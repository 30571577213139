import React, {useState, useRef } from "react";
import styled from "styled-components";
import {graphql} from "gatsby";
import { useForm } from "react-hook-form";
import Modal from '../components/Modal';
import SEO from "../components/SEO";
import { GatsbyImage } from "gatsby-plugin-image";

const ContactStyles = styled.div`
  padding: 6.6vmax 3vw;
  display: flex;
  width: auto;
  max-width: 2000px;
  justify-content: center;
  .contact-content {
    width: 70%;
    display: flex;
    .contact-form__image {
      flex: 0 0 35%;
      margin-right: 10%;
    }
    .contact-form__form {
      flex: 0 0 55%;
      p {
        font-size: calc((1.2 - 1) * 1.2vw + 1rem);
        margin: 0;
      }
      .pre-contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1.5rem;
        p {
          margin-bottom: .8rem;
        }
        a {
            color: #000000;
            font-size: calc((1.2 - 1) * 1.2vw + 1rem);
            text-decoration: none;
            margin-bottom: .7rem;
        }
        a:hover {
          color: rgba(0,0,0,.65);
        }
      }
    }
  }

  @media screen and (max-width: 800px){
    .contact-content {
      width: auto;
      flex-direction: column;
      .contact-form__image {
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
  }
`;

const FormStyles = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    font-size: calc((1.2 - 1) * 1.2vw + 1rem);
    font-weight: 300;
    margin-bottom: .5rem;
  }

  input {
    margin-bottom: 1.5rem;
    font-size: 14px;
    padding: 12px;
    border: 1px solid #cccccc;
    background: #fafafa;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
  }

  input:focus {
    background: transparent;
  }

  input[type="submit"] {
    letter-spacing: 1px;
    cursor: pointer;
    color: #000000;
    background: transparent;
    transition: background-color .2s ease;
    border: 1px solid #000000;
    font-size: .9rem;
    display: inline;
    width: auto;
    border-radius: 0;
    padding: 1rem 1.5rem;
  }

  input[type="submit"]:hover {
    background-color: #000000;
    color: #ffffff;
  }

  textarea {
    border: 1px solid #cccccc;
    background: #fafafa;
    border-radius: 2px;
    margin-bottom: 2rem;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
  }


  input:focus-visible,
  textarea:focus-visible {
    outline: #000000 auto 1px;
  }
`;

function ContactPage({ data }){
    const { register, handleSubmit, reset } = useForm();
    const [successMessage, setSM] = useState("");
    const modalRef = useRef();

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }


     function onSubmit(data){
        fetch('/', {
            method: 'POST',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body:  encode({ "form-name": "contact", ...data })
        })
            .then(() => {
                setSM(`Thank you ${data.name} for your message`);
                modalRef.current.openModal();
                reset();
            })
            .catch((error) => alert(error));
    }

    return (
        <>
            <SEO title={"Contact"}/>
            <ContactStyles>
                <Modal ref={modalRef}>{successMessage}</Modal>
                <div className="contact-content">
                  <div className="contact-form__image">
                    <GatsbyImage image={data.contactinfo.image.asset.gatsbyImageData} alt={"Alexander Peterson Image"} />
                  </div>
                  <div className="contact-form__form">
                    <div className="pre-contact">
                        <p>Alexander Peterson</p>
                        
                        <a href={`tel:${data.contactinfo.phone}`}>{data.contactinfo.phone}</a>
                        
                        <a href={`mailto:${data.contactinfo.email}`}>{data.contactinfo.email}</a>
                    </div>
                    <FormStyles
                        name="contact"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <input type="hidden" name="contact" value="contact"/>
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" {...register("name")}/>
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" {...register("email")}/>

                        <label htmlFor="phone">Phone</label>
                        <input type="tel" name="phone" {...register("phone")}/>

                        <label htmlFor="subject">Subject</label>
                        <input type="text" name="subject" {...register("subject")}/>

                        <label htmlFor="comments">Message</label>
                        <textarea name="comments" rows="6" cols="50" {...register("comments")}></textarea>
                        <input type="submit" value="Send"/>
                    </FormStyles>
                  </div>
                </div>
            </ContactStyles>
        </>
    )
}

export default ContactPage;

export const contactInfo = graphql`
    query ContactInfoQuery {
        contactinfo: sanityContactInfo {
              email
              phone
              image {
                asset {
                  gatsbyImageData
                }
              }
            }
          }
`;
