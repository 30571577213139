import React, { useState, forwardRef, useImperativeHandle } from "react";
import Portal from "../portal";
import styled from "styled-components";

const PortalStyles = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  /* Modal Content/Box */
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 10px 15px;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid #888;
    width: 20%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }
  
  .modal-header {
    display: flex;
    justify-content: flex-end;
    span {
      font-size: 22px;
      cursor: pointer;
    }
  }
  
  .modal-body {
    text-align: center;
    padding: 3rem 0 4rem 0;
    p {
      font-size: 20px;
      letter-spacing: 1px;
      margin: 0;
    }
  }
  
  @keyframes animatetop {
    from {opacity:0}
    to {opacity:1}
  }
  
`;

const Modal = forwardRef((props, ref) => {

    const [display, setDisplay] = useState(false);

    useImperativeHandle(
        ref,
        () => {
            return {
                openModal: () => handleOpen(),
                closeModal: () => handleClose(),
            }
        }
    )

    const handleOpen = () => {
        setDisplay(true);
    }

    const handleClose = () => {
        setDisplay(false);
    }

    if (display) {
        return (
            <Portal>
                <PortalStyles>
                    <div className="modal-content">
                        <div className="modal-header">
                            <span onClick={handleClose}>&times;</span>
                        </div>
                        <div className="modal-body">
                            <p>{props.children}</p>
                        </div>
                    </div>
                </PortalStyles>
            </Portal>
        )
    }

    return null
})

export default Modal
